export const environment = {
  production: false,
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const CONFIG = {
  production: true,
// URL: "http://localhost:8080/api/",
URL:"https://kairos.back.dev.dpc.com.tn/api/",


};
